import { IUploadScopeDTO } from "@/models/FormData";
import {
  IScopeOneScopeThreeDTO,
  IScopeOneScopeThreeParameters,
  IScopeOneScopeThreeRequestDetailParamsDTO,
  IScopeOneScopeThreeRequestTotal,
  IScopeOneScopeThreeResponseTotal,
  IScopeOneScopeThreeResponseHistory,
  IScopeOneScopeThreeHistoryRequest,
} from "@/models/ScopeOneScopeThreeEmissions";
import { IPagination } from "@/models/Pagination";
import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import {
  convertDataToMonthValue,
  convertDataToMonthValueWithFormat,
} from "@/utils/helpers/buildDataFormat";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { Scope1Scope3API } from "@/services/scope1scope3";
import { IScopeOneThreeDataChart } from "@/models/Scope1Scope3";
import {
  covertDataChartMonthValue,
  covertDataChartScope1And3,
  covertDataChartScope2,
  covertDataChartYearValue,
} from "@/utils/helpers/convertDataChart";
const name = "ScopeOneScopeThree";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class ScopeOneScopeThreeModule extends VuexModule {
  private pagination = {} as IPagination;

  private errorUploadScopeOne = {} as ErrorResponse;
  private errorUploadScopeThree = {} as ErrorResponse;
  private errorScopeOneRequestDataDetail = {} as ErrorResponse;
  private errorScopeThreeRequestDataDetail = {} as ErrorResponse;
  private errorScopeOneRequest = {} as ErrorResponse;
  private errorScopeThreeRequest = {} as ErrorResponse;
  private errorScopeOneTotal = {} as ErrorResponse;
  private errorScopeThreeTotal = {} as ErrorResponse;
  private errorScopeOneHistory = {} as ErrorResponse;
  private errorScopeThreeHistory = {} as ErrorResponse;

  private scopeOneRequestData = [] as IScopeOneScopeThreeDTO[];
  private scopeOneRequestDataDetail = {} as any;
  private scopeOneTotal = {} as IScopeOneScopeThreeResponseTotal;

  private scopeThreeRequestData = [] as IScopeOneScopeThreeDTO[];
  private scopeThreeRequestDataDetail = {} as any;
  private scopeThreeTotal = {} as IScopeOneScopeThreeResponseTotal;
  private scopeThreeDataChart = [] as IScopeOneThreeDataChart[][];
  private scopeOneDataChart = [] as IScopeOneThreeDataChart[][];
  private currentOptionYearS1 = "" as string;
  private currentOptionYearS3 = "" as string;

  private scopeOneHistory = [] as any;
  private scopeThreeHistory = [] as any;

  private isLoadingScopeOneHistory = false;
  private isLoadingScopeThreeHistory = false;

  private isLoadingScopeOneRequest = false;
  private isLoadingScopeThreeRequest = false;

  private isLoadingScopeOneRequestDetail = false;
  private isLoadingScopeThreeRequestDetail = false;

  private isReadonlyFormScope1 = false;
  private isReadonlyFormScope3 = false;

  private isLoadingScopeOneTotal = false;
  private isLoadingScopeThreeTotal = false;

  get scopeOneRequestGetter() {
    return this.scopeOneRequestData;
  }

  get scopeThreeRequestGetter() {
    return this.scopeThreeRequestData;
  }

  get isReadonlyFormScope1Getter() {
    return this.isReadonlyFormScope1;
  }

  get isReadonlyFormScope3Getter() {
    return this.isReadonlyFormScope3;
  }

  get scopeOneRequestDataDetailGetter() {
    return this.scopeOneRequestDataDetail;
  }

  get scopeThreeRequestDataDetailGetter() {
    return this.scopeThreeRequestDataDetail;
  }

  get isLoadingScopeOneRequestGetter() {
    return this.isLoadingScopeOneRequest;
  }

  get isLoadingScopeThreeRequestGetter() {
    return this.isLoadingScopeThreeRequest;
  }

  get isLoadingScopeOneRequestDetailGetter() {
    return this.isLoadingScopeOneRequestDetail;
  }

  get isLoadingScopeThreeRequestDetailGetter() {
    return this.isLoadingScopeThreeRequestDetail;
  }

  get currentOptionYearS1Getter() {
    return this.currentOptionYearS1;
  }

  get currentOptionYearS3Getter() {
    return this.currentOptionYearS3;
  }

  get scopeOneTotalGetter() {
    return this.scopeOneTotal;
  }

  get scopeThreeTotalGetter() {
    return this.scopeThreeTotal;
  }

  get isLoadingScopeOneTotalGetter() {
    return this.isLoadingScopeOneTotal;
  }

  get isLoadingScopeThreeTotalGetter() {
    return this.isLoadingScopeThreeTotal;
  }
  get scopeThreeDataChartGetter() {
    return this.scopeThreeDataChart;
  }
  get scopeOneDataChartGetter() {
    return this.scopeOneDataChart;
  }
  get scopeOneHistoryGetter() {
    return this.scopeOneHistory;
  }
  get isLoadingScopeOneHistoryGetter() {
    return this.isLoadingScopeOneHistory;
  }
  get scopeThreeHistoryGetter() {
    return this.scopeThreeHistory;
  }
  get isLoadingScopeThreeHistoryGetter() {
    return this.isLoadingScopeThreeHistory;
  }

  @Mutation
  uploadScopeOneFailure(error: ErrorResponse) {
    this.errorUploadScopeOne = error;
  }
  @Mutation
  uploadScopeOneSuccess() {
    ElMessage({
      message: "Upload Scope 1 success.",
      grouping: true,
      type: "success",
    });
    this.errorUploadScopeOne = {} as ErrorResponse;
  }
  @Action
  async uploadScopeOneAction(payload: IUploadScopeDTO) {
    const Scope1Scop3APIService = new Scope1Scope3API();
    const { error } = await Scope1Scop3APIService.uploadScopeOne(payload);
    if (error) {
      this.uploadScopeOneFailure(error);
      return;
    }
    this.uploadScopeOneSuccess();
  }

  @Mutation
  LoadingScopeOneRequest(loading: boolean) {
    this.isLoadingScopeOneRequest = loading;
  }
  @Mutation
  getScopeOneRequestFailure(error: ErrorResponse) {
    this.errorScopeOneRequest = error;
    this.isLoadingScopeOneRequest = false;
  }
  @Mutation
  getScopeOneRequestSuccess(data: any) {
    const { Collection, ...rest } = data;
    this.errorScopeOneRequest = {} as ErrorResponse;
    this.scopeOneRequestData = Collection;
    this.pagination = rest;
    this.isLoadingScopeOneRequest = false;
  }
  @Action
  async getScopeOneRequestAction(payload: IScopeOneScopeThreeParameters) {
    this.LoadingScopeOneRequest(true);
    const Scope1Scop3APIService = new Scope1Scope3API();
    const { data, error } = await Scope1Scop3APIService.getScopeOneRequest(
      payload
    );
    if (error) {
      this.getScopeOneRequestFailure(error);
      return;
    }
    this.getScopeOneRequestSuccess(data);
  }

  @Mutation
  loadingScopeOneRequestDetail(isLoading: boolean) {
    this.isLoadingScopeOneRequestDetail = isLoading;
  }
  @Mutation
  getScopeOneRequestDetailFailure(error: ErrorResponse) {
    this.isLoadingScopeOneRequestDetail = false;
    this.errorScopeOneRequestDataDetail = error;
  }
  @Mutation
  getScopeOneRequestDetailSuccess(data: any) {
    this.isLoadingScopeOneRequestDetail = false;
    this.errorScopeOneRequestDataDetail = {} as ErrorResponse;
    this.scopeOneRequestDataDetail = convertDataToMonthValue(data) as any;
  }
  @Action
  async getScopeOneRequestDetailAction(
    payload: IScopeOneScopeThreeRequestDetailParamsDTO
  ) {
    this.loadingScopeOneRequestDetail(true);
    const Scope1Scop3APIService = new Scope1Scope3API();
    const { data, error } =
      await Scope1Scop3APIService.getScopeOneRequestDetail(payload);
    if (error) {
      this.getScopeOneRequestDetailFailure(error);
      return;
    }
    this.getScopeOneRequestDetailSuccess(data);
  }

  @Mutation
  emptyScopeOneRequest() {
    const { Collection, ...rest } = {} as any;
    this.scopeOneRequestData = Collection;
    this.pagination = rest;
  }

  @Mutation
  emptyScopeThreeRequest() {
    const { Collection, ...rest } = {} as any;
    this.scopeThreeRequestData = Collection;
    this.pagination = rest;
  }

  @Mutation
  emptyScopeOneRequestDetail() {
    this.scopeOneRequestDataDetail = { MonthValue: [] };
  }

  @Mutation
  emptyScopeThreeRequestDetail() {
    this.scopeThreeRequestDataDetail = { MonthValue: [] };
  }

  @Mutation
  toggleS1ButtonType(isReadonlyFormScope1 = false) {
    this.isReadonlyFormScope1 = isReadonlyFormScope1;
  }

  @Mutation
  toggleS3ButtonType(isReadonlyFormScope3 = false) {
    this.isReadonlyFormScope3 = isReadonlyFormScope3;
  }

  @Mutation
  storageCurrentOptionYearS1(value: string) {
    this.currentOptionYearS1 = value;
  }

  @Mutation
  storageCurrentOptionYearS3(value: string) {
    this.currentOptionYearS3 = value;
  }

  @Mutation
  uploadScopeThreeFailure(error: ErrorResponse) {
    this.errorUploadScopeOne = error;
  }
  @Mutation
  uploadScopeThreeSuccess() {
    ElMessage({
      message: "Upload Scope 3 success.",
      grouping: true,
      type: "success",
    });
    this.errorUploadScopeOne = {} as ErrorResponse;
  }
  @Action
  async uploadScopeThreeAction(payload: IUploadScopeDTO) {
    const Scope1Scop3APIService = new Scope1Scope3API();
    const { error } = await Scope1Scop3APIService.uploadScopeThree(payload);
    if (error) {
      this.uploadScopeThreeFailure(error);
      return;
    }
    this.uploadScopeThreeSuccess();
  }

  @Mutation
  LoadingScopeThreeRequest(loading: boolean) {
    this.isLoadingScopeThreeRequest = loading;
  }
  @Mutation
  getScopeThreeRequestFailure(error: ErrorResponse) {
    this.errorScopeThreeRequest = error;
    this.isLoadingScopeThreeRequest = false;
  }
  @Mutation
  getScopeThreeRequestSuccess(data: any) {
    const { Collection, ...rest } = data;
    this.errorScopeThreeRequest = {} as ErrorResponse;
    this.scopeThreeRequestData = Collection;
    this.pagination = rest;
    this.isLoadingScopeThreeRequest = false;
  }
  @Action
  async getScopeThreeRequestAction(payload: IScopeOneScopeThreeParameters) {
    this.LoadingScopeThreeRequest(true);
    const Scope1Scop3APIService = new Scope1Scope3API();
    const { data, error } = await Scope1Scop3APIService.getScopeThreeRequest(
      payload
    );
    if (error) {
      this.getScopeThreeRequestFailure(error);
      return;
    }
    this.getScopeThreeRequestSuccess(data);
  }

  @Mutation
  loadingScopeThreeRequestDetail(isLoading: boolean) {
    this.isLoadingScopeThreeRequestDetail = isLoading;
  }
  @Mutation
  getScopeThreeRequestDetailFailure(error: ErrorResponse) {
    this.errorScopeThreeRequestDataDetail = error;
    this.isLoadingScopeThreeRequestDetail = false;
  }
  @Mutation
  getScopeThreeRequestDetailSuccess(data: any) {
    this.errorScopeThreeRequestDataDetail = {} as ErrorResponse;
    this.scopeThreeRequestDataDetail = convertDataToMonthValue(data) as any;
    this.isLoadingScopeThreeRequestDetail = false;
  }
  @Action
  async getScopeThreeRequestDetailAction(
    payload: IScopeOneScopeThreeRequestDetailParamsDTO
  ) {
    this.loadingScopeThreeRequestDetail(true);
    const Scope1Scop3APIService = new Scope1Scope3API();
    const { data, error } =
      await Scope1Scop3APIService.getScopeThreeRequestDetail(payload);
    if (error) {
      this.getScopeThreeRequestDetailFailure(error);
      return;
    }
    this.getScopeThreeRequestDetailSuccess(data);
  }
  @Mutation
  emptyscopeOneTotal() {
    this.scopeOneTotal = {} as IScopeOneScopeThreeResponseTotal;
    this.scopeOneDataChart = [];
  }
  @Mutation
  loadingScopeOneTotal(isLoading: boolean) {
    this.isLoadingScopeOneTotal = isLoading;
  }
  @Mutation
  getScopeOneTotalFailure(error: ErrorResponse) {
    this.isLoadingScopeOneTotal = false;
    this.errorScopeOneTotal = error;
  }
  @Mutation
  getScopeOneTotalSuccess(data: IScopeOneScopeThreeResponseTotal[]) {
    this.isLoadingScopeOneTotal = false;
    this.errorScopeOneTotal = {} as ErrorResponse;
    this.scopeOneTotal = convertDataToMonthValueWithFormat(data) as any;
    this.scopeOneDataChart = covertDataChartMonthValue(data);
  }
  @Action
  async getScopeOneTotalAction(payload: IScopeOneScopeThreeRequestTotal) {
    const Scope1Scop3APIService = new Scope1Scope3API();
    this.loadingScopeOneTotal(true);
    const { data, error } = await Scope1Scop3APIService.getScopeOneTotal(
      payload
    );
    if (error) {
      this.getScopeOneTotalFailure(error);
      return;
    }
    this.getScopeOneTotalSuccess(data);
  }
  @Mutation
  emptyScopeThreeTotal() {
    this.scopeThreeTotal = {} as IScopeOneScopeThreeResponseTotal;
    this.scopeThreeDataChart = [];
  }
  @Mutation
  loadingScopeThreeTotal(isLoading: boolean) {
    this.isLoadingScopeThreeTotal = isLoading;
  }
  @Mutation
  getScopeThreeTotalFailure(error: ErrorResponse) {
    this.isLoadingScopeThreeTotal = false;
    this.errorScopeThreeTotal = error;
  }
  @Mutation
  getScopeThreeTotalSuccess(data: IScopeOneScopeThreeResponseTotal) {
    this.isLoadingScopeThreeTotal = false;
    this.errorScopeThreeTotal = {} as ErrorResponse;
    this.scopeThreeTotal = convertDataToMonthValueWithFormat(data) as any;
    this.scopeThreeDataChart = covertDataChartMonthValue(data);
  }
  @Action
  async getScopeThreeTotalAction(payload: IScopeOneScopeThreeRequestTotal) {
    const Scope1Scop3APIService = new Scope1Scope3API();
    this.loadingScopeThreeTotal(true);
    const { data, error } = await Scope1Scop3APIService.getScopeThreeTotal(
      payload
    );
    if (error) {
      this.getScopeThreeTotalFailure(error);
      return;
    }
    this.getScopeThreeTotalSuccess(data);
  }

  @Action
  async getScopeOneHistoryAction(payload: IScopeOneScopeThreeHistoryRequest) {
    const Scope1Scop3APIService = new Scope1Scope3API();
    this.loadingScopeOneHistory(true);
    const { data, error } = await Scope1Scop3APIService.getScopeOneHistory(
      payload
    );
    if (error) {
      this.getScopeOneHistoryFailure(error);
      return;
    }
    this.getScopeOneHistorySuccess(data);
  }
  @Mutation
  getScopeOneHistorySuccess(data: IScopeOneScopeThreeResponseHistory[]) {
    this.scopeOneHistory = covertDataChartYearValue(data);
    this.isLoadingScopeOneHistory = false;
  }
  @Mutation
  getScopeOneHistoryFailure(error: ErrorResponse) {
    this.errorScopeOneHistory = error;
    this.isLoadingScopeOneHistory = false;
  }
  @Mutation
  loadingScopeOneHistory(isLoading: boolean) {
    this.isLoadingScopeOneHistory = isLoading;
  }
  @Mutation
  emptyScopeOneHistory() {
    this.scopeOneHistory = [];
  }

  @Action
  async getScopeThreeHistoryAction(payload: IScopeOneScopeThreeHistoryRequest) {
    const Scope1Scop3APIService = new Scope1Scope3API();
    this.loadingScopeThreeHistory(true);
    const { data, error } = await Scope1Scop3APIService.getScopeThreeHistory(
      payload
    );
    if (error) {
      this.getScopeThreeHistoryFailure(error);
      return;
    }
    this.getScopeThreeHistorySuccess(data);
  }
  @Mutation
  getScopeThreeHistorySuccess(data: IScopeOneScopeThreeResponseHistory[]) {
    this.scopeThreeHistory = covertDataChartYearValue(data);
    this.isLoadingScopeThreeHistory = false;
  }
  @Mutation
  getScopeThreeHistoryFailure(error: ErrorResponse) {
    this.errorScopeThreeHistory = error;
    this.isLoadingScopeThreeHistory = false;
  }
  @Mutation
  loadingScopeThreeHistory(isLoading: boolean) {
    this.isLoadingScopeThreeHistory = isLoading;
  }
  @Mutation
  emptyScopeThreeHistory() {
    this.scopeThreeHistory = [];
  }
}

export default getModule(ScopeOneScopeThreeModule);
